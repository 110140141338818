<template>
  <div class="vacation-templates">
    <!-- <template v-if="$route.params.type === 'common'"> -->
    <template>
      <!-- ЗАЯВЛЕНИЕ -->
      <section>
        <header>ЗАЯВЛЕНИЕ</header>
        <main>
          <div class="row">
            <Input
              v-model="template.application1"
              placeholder="До Изп. Директор"
            />
            <Input
              v-model="template.application2"
              placeholder="на КЦМ АД Пловдив"
            />
          </div>
          <div class="row">
            <Input
              class="input-wrapper--title"
              v-model="template.application3"
              placeholder="ЗАЯВЛЕНИЕ"
            />
          </div>
          <div class="row">
            <Input v-model="template.application4" placeholder="От" />
            <Input
              v-model="template.application5"
              placeholder="Работник/Служител"
            />
          </div>
          <div class="row">
            <Input
              v-model="template.application6"
              placeholder="Служебен номер"
            />
            <Input v-model="template.application7" placeholder="На длъжност" />
            <Input
              v-model="template.application8"
              placeholder="Остатък отпуск"
            />
          </div>
          <div class="row">
            <Input
              class="input-wrapper--bold input-wrapper--center"
              v-model="template.application9"
              placeholder="Господин Изпълнителен Директор,"
            />
          </div>
          <div class="row">
            <Input
              class="input-wrapper--bold input-wrapper--center"
              v-model="template.application10"
              placeholder="Моля, за разрешението Ви да ползвам:"
            />
          </div>
          <div class="row">
            <Input v-model="template.application11" placeholder="От дата:" />
            <Input
              v-model="template.application12"
              placeholder="До дата включително:"
            />
          </div>
          <div class="row">
            <Input
              v-model="template.application13"
              placeholder="Дни отпуск (автом.):"
            />
          </div>
          <div class="row">
            <Input
              v-model="template.application14"
              placeholder="Прикачване на документи, на основание на който се разрешава отпуска"
            />
          </div>
          <div class="row">
            <Input
              v-model="template.application15"
              placeholder="Прикачване на документи (ако има такива)"
            />
          </div>
          <div class="row">
            <Input
              v-model="template.application16"
              placeholder="Дата на подписване:"
            />
          </div>
          <div class="row">
            <Input
              v-model="template.application17"
              placeholder="Надявам се молбата ми да бъде удовлетворена."
            />
          </div>
          <div class="row">
            <Input
              v-model="template.application18"
              placeholder="Уведомен съм, че ще получа по електронен път становището на Работодателя относно настоящата молба"
            />
          </div>
          <div class="row">
            <Input
              v-model="template.application19"
              placeholder="Работник/служител:"
            />
            <Input
              v-model="template.application20"
              placeholder="Собственоръчни подпис и име"
            />
          </div>
          <div class="row">
            <Input
              v-model="template.application21"
              placeholder="Пряк ръководител:"
            />
            <Input
              v-model="template.application22"
              placeholder="Собственоръчни подпис и име"
            />
          </div>
        </main>
      </section>
      <!-- ИМЕНА НА ОТПУСКИ И ЧЛЕНОВЕТЕ ПО КТ -->
      <section>
        <header>ИМЕНА НА ОТПУСКИ И ЧЛЕНОВЕТЕ ПО КТ</header>
        <main>
          <div class="row">
            <Input
              v-model="template.nameAndArticle1"
              class="input-wrapper--bold"
              placeholder="Платен годишен отпуск"
            />
            <Input
              v-model="template.nameAndArticle2"
              class="input-wrapper--right"
              placeholder="чл.155, 156, 156а и 168 от КТ"
            />
          </div>
          <div class="row">
            <Input
              v-model="template.nameAndArticle3"
              class="input-wrapper--bold"
              placeholder="Целеви отпуск"
            />
            <Input
              v-model="template.nameAndArticle4"
              class="input-wrapper--right"
              placeholder="чл. 157, ал. 1, т. 1, т. 2, т. 3 от КТ"
            />
          </div>
          <div class="row">
            <Input
              v-model="template.nameAndArticle5"
              class="input-wrapper--sub"
              placeholder="Отпуск за смърт"
            />
            <Input
              v-model="template.nameAndArticle6"
              class="input-wrapper--right"
              placeholder="чл. 157, ал. 1, т. 1"
            />
          </div>
          <div class="row">
            <Input
              v-model="template.nameAndArticle7"
              class="input-wrapper--sub"
              placeholder="Отпуск за брак"
            />
            <Input
              v-model="template.nameAndArticle8"
              class="input-wrapper--right"
              placeholder="чл. 157, ал. 1, т. 2"
            />
          </div>
          <div class="row">
            <Input
              v-model="template.nameAndArticle9"
              class="input-wrapper--sub"
              placeholder="Отпуск за кръводаряване"
            />
            <Input
              v-model="template.nameAndArticle10"
              class="input-wrapper--right"
              placeholder="чл. 157, ал. 1, т. 3"
            />
          </div>
          <div class="row">
            <Input
              v-model="template.nameAndArticle11"
              class="input-wrapper--bold"
              placeholder="Платен служебен отпуск"
            />
            <Input
              v-model="template.nameAndArticle12"
              class="input-wrapper--right"
              placeholder="чл. 161 от КТ"
            />
          </div>
          <div class="row">
            <Input
              v-model="template.nameAndArticle13"
              class="input-wrapper--bold"
              placeholder="Отпуск за баща, при раждане на дете"
            />
            <Input
              v-model="template.nameAndArticle14"
              class="input-wrapper--right"
              placeholder="чл.163, ал.7 от КТ"
            />
          </div>
          <div class="row">
            <Input
              v-model="template.nameAndArticle15"
              class="input-wrapper--bold"
              placeholder="Ученически отпуск"
            />
            <Input
              v-model="template.nameAndArticle16"
              class="input-wrapper--right"
              placeholder="чл. 169 от КТ"
            />
          </div>
          <div class="row">
            <Input
              v-model="template.nameAndArticle17"
              class="input-wrapper--sub"
              placeholder="Платен ученически отпуск"
            />
            <Input
              v-model="template.nameAndArticle18"
              class="input-wrapper--right"
              placeholder="чл. 169 от КТ"
            />
          </div>
          <div class="row">
            <Input
              v-model="template.nameAndArticle19"
              class="input-wrapper--sub"
              placeholder="Платен ученически отпуск за дипломиране"
            />
            <Input
              v-model="template.nameAndArticle20"
              class="input-wrapper--right"
              placeholder="чл. 169 от КТ"
            />
          </div>
          <div class="row">
            <Input
              v-model="template.nameAndArticle21"
              class="input-wrapper--sub"
              placeholder="Неплатен ученически отпуск"
            />
            <Input
              v-model="template.nameAndArticle22"
              class="input-wrapper--right"
              placeholder="чл. 171 от КТ"
            />
          </div>
          <div class="row">
            <Input
              v-model="template.nameAndArticle23"
              class="input-wrapper--bold"
              placeholder="Неплатен отпуск"
            />
            <Input
              v-model="template.nameAndArticle24"
              class="input-wrapper--right"
              placeholder="чл. 160, ал. 2 от КТ"
            />
          </div>
          <div class="row">
            <Input
              v-model="template.nameAndArticle25"
              class="input-wrapper--bold"
              placeholder="Други"
            />
            <Input
              v-model="template.nameAndArticle26"
              class="input-wrapper--right"
              placeholder="чл. 157, чл. 161 от КТ"
            />
          </div>
          <div class="row">
            <Input
              v-model="template.nameAndArticle27"
              class="input-wrapper--sub"
              placeholder="Платен отпуск синдикални дейци"
            />
            <Input
              v-model="template.nameAndArticle28"
              class="input-wrapper--right"
              placeholder="чл. 159 от КТ"
            />
          </div>
          <div class="row">
            <Input
              v-model="template.nameAndArticle29"
              class="input-wrapper--sub"
              placeholder="Отпуск по график"
            />
            <Input
              v-model="template.nameAndArticle30"
              class="input-wrapper--right"
              placeholder="чл. 161 от КТ"
            />
          </div>
        </main>
      </section>
      <!-- ЗАПОВЕД -->
      <section>
        <header>ЗАПОВЕД</header>
        <main>
          <div class="row">
            <Input v-model="template.order1" placeholder="До Изп. Директор" />
            <Input v-model="template.order2" placeholder="на КЦМ АД Пловдив" />
          </div>
          <div class="row">
            <Input v-model="template.order3" placeholder="Разрешавам отпуска" />
            <Input
              v-model="template.order4"
              placeholder="Не разрешавам отпуска"
            />
          </div>
          <div class="row">
            <Input
              class="input-wrapper--title"
              v-model="template.order5"
              placeholder="ЗАПОВЕД"
            />
          </div>
          <div class="row">
            <Input
              v-model="template.order6"
              placeholder="На основание чл.173, ал.1 от КТ и Заповед № 77/31.10.2017г. на Изп.Директор на КЦМ АД"
            />
          </div>
          <div class="row">
            <Input
              class="input-wrapper--bold"
              v-model="template.order7"
              placeholder="Разрешавам на"
            />
            <Input
              class="input-wrapper--bold"
              v-model="template.order8"
              placeholder="Не разрешавам на"
            />
          </div>
          <div class="row">
            <Input v-model="template.order9" placeholder="сл.№" />
            <Input v-model="template.order10" placeholder="на длъжност" />
          </div>
          <div class="row">
            <Input v-model="template.order11" placeholder="в размер на" />
          </div>
          <div class="row">
            <Input
              v-model="template.order12"
              placeholder="работен ден (ед.ч.)"
            />
            <Input
              v-model="template.order13"
              placeholder="работни дни (мн.ч.)"
            />
          </div>
          <div class="row">
            <Input
              v-model="template.order14"
              placeholder="календарен ден (ед.ч.)"
            />
            <Input
              v-model="template.order15"
              placeholder="календарни дни (мн.ч.)"
            />
          </div>
          <div class="row">
            <Input v-model="template.order16" placeholder="считано от" />
            <Input v-model="template.order17" placeholder="до" />
          </div>
          <div class="row">
            <Input v-model="template.order18" placeholder="Работникът" />
            <Input
              class="input-wrapper--bold"
              v-model="template.order19"
              placeholder="да се яви на работа"
            />
            <Input v-model="template.order20" placeholder="на" />
          </div>
          <div class="row">
            <Input
              v-model="template.order21"
              placeholder="по утвърдения му график за работа. Уведомление за съгласувана и подписана заповед да получат лицето, ръководителя и специалисти УЧР за сведение и изпълнение."
            />
          </div>
          <div class="row">
            <Input
              v-model="template.order22"
              placeholder="Ръководител ЦП/ОП/ТУ/Цех/Отдел:"
            />
            <Input
              v-model="template.order23"
              placeholder="Собственоръчни подпис и име"
            />
          </div>
        </main>
      </section>
      <!-- ЧОВЕШКИ РЕСУРСИ -->
      <section>
        <header>ЧОВЕШКИ РЕСУРСИ</header>
        <main>
          <div class="row">
            <Input
              v-model="template.hr1"
              placeholder="Решение на Човешки ресурси"
            />
            <Input v-model="template.hr2" placeholder="за финално одобрение" />
          </div>
          <div class="row">
            <Input v-model="template.hr3" placeholder="Разрешавам отпуска" />
            <Input v-model="template.hr4" placeholder="Не разрешавам отпуска" />
          </div>
          <div class="row">
            <Input
              v-model="template.hr5"
              placeholder="Ръководител Човешки ресурси"
            />
            <Input
              v-model="template.hr6"
              placeholder="Собственоръчни подпис и име"
            />
          </div>
        </main>
      </section>
    </template>
    <!-- <template v-else>
      <section>
        <header>
          ТЕКСТОВЕ - {{ types[common].toUpperCase() }}
        </header>
        <main>
          <div class="row">
            <Input v-model="template.byType1" placeholder="На основание" />
          </div>
          <div class="row">
            <Input
              v-model="template.byType2"
              placeholder="чл.173, ал.1 от КТ и Заповед № 77/31.10.2017г. на Изп. Директор на КЦМ АД"
            />
          </div>
          <div class="row">
            <Input v-model="template.byType3" placeholder="Разрешавам" />
          </div>
          <div class="row">
            <Input v-model="template.byType4" placeholder="Не разрешавам на" />
          </div>
          <div class="row">
            <Input v-model="template.byType5" placeholder="сл.№" />
          </div>
          <div class="row">
            <Input v-model="template.byType6" placeholder="на длъжност" />
          </div>
          <div class="row">
            <Input
              v-model="template.byType7"
              placeholder="платен годишен отпуск по чл.155, 156 , 156а и 168 от КТ"
            />
          </div>
          <div class="row">
            <Input v-model="template.byType8" placeholder="в размер на" />
          </div>
        </main>
      </section>
    </template> -->
    <div class="vacation-templates__buttons">
      <div>
        <p class="warning">
          <span class="icon">j</span>
          Запазените промени ще се отразят във всички бланки
        </p>
      </div>
      <div class="buttons">
        <transition name="fade">
          <Loading :success="success" v-if="loading" />
        </transition>
        <Button @click="cancel">Откажи</Button>
        <Button class="button--blue" @click="save" :disabled="loading"
          >Запиши</Button
        >
      </div>
    </div>
  </div>
</template>

<script>
import types from '@/data/vacation-types.json';
import Input from '@/components/inputs/Input';
import Button from '@/components/Button';
import Loading from '@/components/Loading';
export default {
  components: {
    Input,
    Button,
    Loading,
  },
  watch: {
    $route: {
      handler: 'getTemplate',
      immediate: true,
    },
  },
  data() {
    return {
      types,
      loading: false,
      success: undefined,
      template: {},
    };
  },
  methods: {
    cancel() {
      this.$router.push('/settings/templates');
    },
    getTemplate() {
      this.template.type = 'common';
      this.$apiService
        .get('/templates/common')
        .then((res) => {
          if (res.data) {
            if (res.data._id) {
              this.template = res.data;
            }
          }
        })
        .catch((err) => console.log(err.response.data));
    },
    isValid() {
      for (let key in this.template) {
        if (!(this.template[key] + '').length) {
          this.$snotify.error('Всички полета са задължителни');
          return false;
        }
      }

      return true;
    },
    save() {
      if (this.isValid()) {
        this.loading = true;

        this.$apiService
          .post('/templates', this.template)
          .then(() => {
            this.success = true;
          })
          .catch((err) => {
            this.success = false;
            console.log(err);
          })
          .then(() => {
            setTimeout(() => {
              if (this.success) {
                this.cancel();
              }

              this.loading = false;
              this.success = undefined;
            }, 1000);
          });
      }
    },
  },
};
</script>
<style lang="scss">
@import '@/scss/base.scss';

.vacation-templates {
  // color: #373737;
  // font-size: $px11;
  margin: 0 auto;
  padding: 40px 0 20px;
  width: 890px;

  section {
    background-color: #f3f3f3;
    border: 1px solid #d0d0d0;
    border-radius: 2px;

    &:not(:first-child) {
      margin-top: 20px;
    }

    header {
      align-items: center;
      background-color: #134685;
      border-radius: 2px;
      color: #fff;
      display: flex;
      font-size: $px11;
      font-weight: 700;
      justify-content: center;
      height: 30px;
    }

    main {
      margin: 0 auto;
      padding: 12.5px 0 18.5px;
      width: 620px;

      .row {
        display: flex;

        &:not(:last-child) {
          margin-bottom: 7px;
        }

        .input-wrapper {
          width: 100%;

          &:not(:first-child) {
            margin-left: 21.5px;
          }

          input {
            background-color: #fff;

            &::placeholder {
              color: rgba($color: #000, $alpha: 0.3);
            }
          }

          &.input-wrapper--title {
            input {
              font-weight: 700;
              letter-spacing: 0.369231rem;
              text-align: center;
            }
          }

          &.input-wrapper--bold {
            input {
              font-weight: 700;
            }
          }

          &.input-wrapper--center {
            input {
              text-align: center;
            }
          }

          &.input-wrapper--right {
            input {
              text-align: right;
            }
          }

          &.input-wrapper--sub {
            input {
              padding-left: 20.5px;
            }
          }
        }
      }
    }
  }

  .vacation-templates__buttons {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 1.3rem;

    .warning {
      align-items: center;
      display: flex;
      color: #df6935;
      font-weight: 500;
      .icon {
        margin-right: 10px;
        font-size: 1.5rem;
      }
    }

    .buttons {
      align-items: center;
      display: flex;
    }

    .button:not(:last-child) {
      margin-right: 10px;
    }
  }
}

@media screen and (max-width: $sm) {
  .vacation-templates {
    padding: 10px;
    width: 100%;

    section {
      main {
        width: 100%;
        padding: 10px;
      }
    }
  }
}
@media screen and (max-width: $s), (orientation:landscape){
  .vacation-templates {
    section {
      main {
        .row {
          flex-direction: column;
          .input-wrapper:not(:first-child) {
            margin: 10px 0 0;
          }
        }
      }
    }
    .vacation-templates__buttons {
      flex-direction: column;
      .warning {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
